<template>
  <div class="system-view">
    <el-row :gutter="25">
      <el-col :span="12">
        <el-card>
          <el-row>
            <el-switch v-model="switch1"></el-switch> 打卡距离工地/基地距离
            <el-input size="mini" type="number" v-model="input1"></el-input> 米之内
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'system-basics',
  data () {
    return {
      switch1: true,
      input1: 1000
    }
  }
}
</script>
